import { 
  GET_PATIENTS,
  GET_ALL_PATIENTS_SUCCESS
} from "../../constants/ActionTypes";

const INIT_STATE = {
  patientList: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PATIENTS:
      return {
        ...state,
        patientList: action.patientList
      }
    case GET_ALL_PATIENTS_SUCCESS: 
      return {
        ...state,
        patientList: action.payload
      }
    default:
      return state;
  }
}
