import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {fetchPatientSuccess} from '../actions/Patient';
import {fetchError} from '../actions/Common';
import {GET_PATIENTS} from '../../constants/ActionTypes';
import { getAllPatientsAsyncCall } from '../../util/apiCall';

const getPatients = () => 
    getAllPatientsAsyncCall(
        (response) => {return response},
        error => error
      );

function* fetchPatientsRequest() {
    try {
        const patients = yield call(getPatients);
        yield put(fetchPatientSuccess(patients.data));
    }
    catch (error) {
        yield put(fetchError(error));
    }
}

export function* fetchPatient() {
    console.log('%cWHATCHING GET PATIENTS', 'color:green')

    yield takeEvery(GET_PATIENTS, fetchPatientsRequest)
}

export default function* rootSaga() {
    yield all([fork(fetchPatient)])
}