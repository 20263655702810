import axios from "axios";
import * as API from "constants/ApiSetting";
import {message} from 'antd'

const axiosCall = axios.create({
  baseURL: API.API_FARMACOVIGILANCIA,
  headers: {
    Authorization: "Bearer SDFASSFGSDFDRGER#$"
  }
});

//Messages
const CONSULTING_PATIENT_ERROR = 'No se pudo completar la consulta de pacientes'

const generalCall = (apiUri, successCallback, errorCallback, errMsg) => {
  axiosCall
    .get(apiUri)
    .then(response => successCallback(response))
    .catch(error => {
      console.error(error)
      message.error(errMsg)
      errorCallback(error)
    });
}

//Patients
//React Thunk
export const getAllPatientsCall = (successCallback, errorCallback = () => {}) => {
  axiosCall
    .get(API.getCreatePatientsUri())
    .then(response => successCallback(response))
    .catch(error => {
      console.error(error)
      message.error("No se pudo completar la consulta de pacientes")
      errorCallback(error)
    });
};

//React Saga
export const getAllPatientsAsyncCall = async () =>
  await axiosCall
    .get(API.getCreatePatientsUri())
    .then(response => response)
    .catch(error => {
      console.error(error);
      message.error("No se pudo completar la consulta de pacientes");
      return error;
    });

export const getPatientCall = (id, successCallback, errorCallback = () => {}) => {
  generalCall(API.getUpdatePatientUri(id), successCallback, errorCallback, CONSULTING_PATIENT_ERROR);
};