export const API_FARMACOVIGILANCIA = 'http://apis.corporacionnovax.com/farmacovigilancia/v0.1'
//export const API_FARMACOVIGILANCIA = ''

//Patient endpoints
export const getCreatePatientsUri = () => {
    return `${API_FARMACOVIGILANCIA}/pacientes`
}

export const getUpdatePatientUri = (id) => {
    return `${API_FARMACOVIGILANCIA}/pacientes/${id}`
}

//Medicine endpoints
export const getCreateMedicinesUri = () => {
    return `${API_FARMACOVIGILANCIA}/medicamentos`
}

export const getUpdateMedicineUri = (id) => {
    return `${API_FARMACOVIGILANCIA}/medicamentos/${id}`
}

//Batch endpoints
export const getCreateBatchsUri = () => {
    return `${API_FARMACOVIGILANCIA}/lotes`
}

export const getUpdateBatchUri = (id) => {
    return `${API_FARMACOVIGILANCIA}/lotes/${id}`
}