import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "../../util/asyncComponent";

const Maintenances = ({match}) => (
  <Switch>
    <Route path={`${match.url}/notifiers`} component={asyncComponent(() => import('./Patients'))}/>
    <Route path={`${match.url}/patients`} component={asyncComponent(() => import('./Patients'))}/>
    <Route path={`${match.url}/medicines`} component={asyncComponent(() => import('./Patients'))}/>
    <Route path={`${match.url}/batches`} component={asyncComponent(() => import('./Patients'))}/>

  </Switch>
);

export default Maintenances;