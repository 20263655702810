import {all} from "redux-saga/effects";
import authSagas from "./Auth";
import notesSagas from "./Notes";
import patientsSagas from "./Patients"

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    notesSagas(),
    patientsSagas()
  ]);
}
