import {GET_ALL_PATIENTS_SUCCESS, GET_PATIENTS} from '../../constants/ActionTypes'
import {fetchStart, fetchSuccess, fetchError} from '../actions/Common'
import {getAllPatientsCall} from '../../util/apiCall'

export const fetchPatientSuccess = patients => {
  return {
    type: GET_ALL_PATIENTS_SUCCESS,
    payload: patients
  }
}

export const getPatients = () => {
  return {
    type: GET_PATIENTS
  }
}

//React Thunk
export const onGetAllPatient = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    getAllPatientsCall(
      (response) => {
        dispatch(fetchSuccess())
        dispatch(fetchPatientSuccess(response.data))
      },
      (error) => {
        dispatch(fetchError(error))
      }
    )
  }
}